


























import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';

import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import RatesService, { RatesServiceS } from '../rates.service';

@Component({
    components: {
        RatesActions,
        PageWrapper,
        RatesHeader,
        TogglePrices,
        ToggleDiff,
    },
})
export default class TablePageLayout extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get isAllChannelsMode() {
        return this.ratesService.settings.provider === 'all';
    }
}
