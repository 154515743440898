






















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
        ToggleDiff,
    },
})
export default class RatesAnalysisComparison extends Vue {
    @Inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    get comparisonType() {
        return this.ratesAnalysisFiltersService.comparisonKey;
    }

    set comparisonType(value: string) {
        if (value !== this.comparisonType) {
            this.ratesAnalysisFiltersService.comparisonKey = value;
        }
    }

    get comparisonValue() {
        return this.ratesAnalysisFiltersService.comparisonValues[0].value;
    }

    set comparisonValue(value: string | number) {
        const findItem = this.comparisonValuesItems.find(element => element.value === value);
        const defaultValue: Item = { value: 1, name: 'Yesterday' };
        this.ratesAnalysisFiltersService.comparisonValues = findItem ? [findItem] : [defaultValue];
    }

    get comparisonTypeItems(): Item[] {
        const { provider } = this.ratesFiltersService.settings;

        if (provider === 'cheapest') {
            if (this.comparisonType === 'diffDays') {
                this.comparisonType = 'provider';

                this.comparisonValue = this.comparisonValuesItems[0].value;
            }

            return this.ratesAnalysisFiltersService.filterList
                .filter(item => item.value !== 'diffDays');
        }

        return this.ratesAnalysisFiltersService.filterList;
    }

    get comparisonValuesItems(): Item[] {
        const { comparisonKey, currentFilterItems } = this.ratesAnalysisFiltersService;

        switch (comparisonKey) {
            case 'priceType':
                return currentFilterItems.map(({ value }) => {
                    const name = String(this.$t(`filterSettings.price.${value}`));
                    return { name, value };
                });
            case 'los':
                return this.losItems;
            default:
                return currentFilterItems;
        }
    }

    get losItems(): Item[] {
        const { currentFilterItems } = this.ratesAnalysisFiltersService;
        const isRatesPage = this.$route.name ? this.$route.name.includes('rate') : false;

        if (isRatesPage && this.ratesFiltersService.isScanDisabledProvider) {
            return currentFilterItems;
        }

        return DEFAULT_LOS
            .filter(v => v !== this.documentFiltersService.settings.los)
            .map((los: number, i) => ({
                value: los,
                name: `LOS${los}`,
            }));
    }

    comparisonValueTitle(selectedValue: number | string) {
        const findElement = this.comparisonValuesItems.find(element => element.value === selectedValue);
        return findElement ? findElement.name : '';
    }

    comparisonTypeTitle(selectedType: string) {
        const findElement = this.comparisonTypeItems.find(element => element.value === selectedType);
        return findElement ? findElement.name : 'diffDays';
    }
}
