







import { Component, Vue } from 'vue-property-decorator';
import RatesAnalysisTable from '@/modules/rates/components/analysis/table/rates-analysis-table.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import TablePageLayout from '@/modules/rates/components/table-page-layout.vue';

@Component({
    components: {
        RatesAnalysisTable,
        RatesAnalysisComparison,
        TablePageLayout,
    },
})
export default class RatesAnalysisTablePage extends Vue {}
