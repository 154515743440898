












































































import { Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DayTooltipTemplate, { TooltipSide } from '@/modules/common/components/ui-kit/day-tooltip-template.vue';

@Component({
    extends: DayTooltipTemplate,
})
export default class RatesTableTooltip extends DayTooltipTemplate {
    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Array,
        default: () => [],
    })
    public disabledColumns!: string[];

    @Prop({
        type: Object,
    })
    public previewValues!: { [k: string]: any };

    @Prop({
        type: String,
    })
    public hotelName!: string;

    @Prop({
        type: Object,
        default: () => ({}),
    })
    public indicators!: { [k: string]: any | false };

    protected reversedChangingSide = true;

    get tooltipWidth() {
        return this.haveIndicators
            ? '350px'
            : '300px';
    }

    get date() {
        const { day } = this;
        const { month, year } = this.documentFiltersService;
        const d = new Date(year, month, day);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    get isActive() {
        const haveData = false
            || this.havePreview
            || this.haveIndicators;

        return haveData && !!this.focusElement;
    }

    get havePreview() {
        return !!Object
            .values(this.previewValues)
            .filter(item => item.value || item.component).length;
    }

    get haveIndicators() {
        return Object
            .values(this.indicators)
            .some(ind => !!ind);
    }
}
