













import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';

@Component({
    components: { CustomSwitch },
})
export default class ToggleDiff extends Vue {
    @Prop({ required: true, type: Boolean })
    value!: boolean;

    change(value: boolean) {
        this.$emit('change', value);
    }
}
