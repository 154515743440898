





















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisNoData from '@/modules/rates/components/analysis/rates-analysis-no-data.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import CiTable, { ITableData, ITableConfig, ICell } from '@/modules/common/components/ci-table';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PriceFilter from '@/modules/common/filters/price.filter';
import RatesTableTooltip from '../../table/table-tooltip.vue';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        RatesAnalysisNoData,
        RatesTableTooltip,
        CiTable,
    },
    filters: { PriceFilter },
})
export default class RatesAnalysisTable extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @Inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(CompsetsServiceS) compsetsService!: CompsetsService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(HotelsServiceS) hotelsService!: HotelsService;
    @Inject(UserServiceS) userService!: UserService;
    @Inject(MealTypesServiceS) mealTypesService!: MealTypesService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    public tableHeight: string | null = null;
    public refreshDropdown: boolean = false;
    public isOpened: boolean = false;
    public headerWidth: number = 120;
    public isPreparing: boolean = true;
    public disabledColumns: string[] = [];
    public tooltipDay: Day = 1;
    public tooltipFocusElement: HTMLElement | null = null;
    public analysisPreview: { [k: string]: any } = {};
    public tooltipHotelName: string = '';

    get competitors() {
        return this.ratesFiltersService.competitors || [];
    }

    get hotels() {
        if (this.isLoading) {
            return [-1, -2, -3];
        }

        if (this.competitors && this.myHotelId) {
            const competitorsCopy = [...this.competitors];
            competitorsCopy.unshift(this.myHotelId);
            return competitorsCopy;
        }

        return [];
    }

    get isLoading() {
        return this.isPreparing
            || (!this.analysisDocument && this.ratesAnalysisService.isLoading)
            || this.ratesService.isLoading;
    }

    get myHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get comparisonValues() {
        this.disabledColumns = [];
        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get analysisSettings() {
        const [comparisonValue] = this.ratesAnalysisFiltersService.comparisonValues;
        return this.ratesAnalysisService
            .getSettingsByComparisonValue(comparisonValue.value as string);
    }

    get baseDocument() {
        return this.isPreparing ? null : this.ratesService.data;
    }

    get analysisDocument() {
        return this.isPreparing ? null : this.ratesAnalysisService.data[0];
    }

    get isNoAnalysisData() {
        return !this.ratesAnalysisService.isLoading
            && !this.ratesAnalysisService.data.length;
    }

    get currency(): string | null {
        const { currency: mainCurrency } = this.ratesService;
        const { currency: compCurrency } = this.ratesAnalysisService;
        return (mainCurrency || compCurrency) ? this.helperService.currencySymbol(mainCurrency || compCurrency) : '';
    }

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get mainCompareTitle() {
        this.disabledColumns = [];

        return this.ratesAnalysisFiltersService.mainCompareTitle;
    }

    get tableData(): ITableData {
        if (!this.myHotelId) {
            return [];
        }

        const dynamicColumns = [
            'Diff',
            String(this.mainCompareTitle),
            this.comparisonValues[0].name,
        ];

        const myHotelId = this.myHotelId as number;

        if (this.isLoading) {
            return [{
                isSticky: true,
                columns: this.hotels.map(hotel => ({
                    title: String(hotel),
                    data: this.days.map(_ => '_____'),
                })),
            }, {
                columns: this.hotels.map(hotel => ({
                    title: String(hotel),
                    data: this.days.map(_ => '_____'),
                })),
            }];
        }

        const mainValues = [] as ICell[];
        const compareValues = [] as ICell[];
        const handleCellHover = (day: Day, hotelId: number) => (e: MouseEvent) => {
            const focusElement = e.currentTarget! as unknown as HTMLElement;

            this.setTooltipData(day, hotelId, focusElement);
        };

        const insertPrice = (day: Day, type: 'main' | 'compare') => {
            const { value, style, hasPrice } = this.getRoomData(day, myHotelId, type === 'compare');
            const targetArray = {
                main: mainValues,
                compare: compareValues,
            }[type];

            targetArray
                .push({
                    value,
                    style: !hasPrice ? style : undefined,
                    onClick: () => this.handleRowClick(day),
                    onHover: handleCellHover(day, myHotelId),
                    onLeave: this.resetTooltipData.bind(this),
                } as ICell);
        };

        this.days.forEach(day => {
            insertPrice(day, 'main');
            insertPrice(day, 'compare');
        });

        const diffValues = this.days.map(day => this.formatDiff(day, myHotelId));
        const competitors = this.competitors || [];

        const data: ITableData = [
            {
                isSticky: true,
                columns: [{
                    title: 'Date',
                    subTitle: '',
                    data: this.days.map(this.dayToDate),
                },
                {
                    title: this.hotelsService.getHotelName(myHotelId),
                    titleStyle: { color: '#00759e' },
                    dynamicColumns,
                    data: [
                        {
                            title: dynamicColumns[0],
                            data: diffValues,
                        },
                        {
                            title: String(this.mainCompareTitle) || '',
                            data: mainValues,
                        },
                        {
                            title: this.comparisonValues[0].name,
                            data: compareValues,
                        },
                    ],
                }],
            },
            {
                columns: competitors.map(hotelId => ({
                    title: false
                        || this.ratesService.getHotelName(hotelId)
                        || this.hotelsService.getHotelName(hotelId),

                    data: [
                        {
                            title: dynamicColumns[0],
                            data: this.days.map(day => this.formatDiff(day, hotelId)),
                        },
                        {
                            title: String(this.mainCompareTitle) || '',
                            data: this.days.map(day => {
                                const { value, style, isBasic } = this.getRoomData(day, hotelId, false);

                                return {
                                    value,
                                    style,
                                    img: isBasic ? BOOKING_BASIC_ICON : null,
                                    onClick: () => this.handleRowClick(day),
                                    onHover: handleCellHover(day, hotelId),
                                    onLeave: this.resetTooltipData.bind(this),
                                } as ICell;
                            }),
                        },
                        {
                            title: this.comparisonValues[0].name,
                            data: this.days.map(day => {
                                const { value, style } = this.getRoomData(day, hotelId, true);

                                return {
                                    value,
                                    style,
                                    onLeave: this.resetTooltipData.bind(this),
                                    onHover: handleCellHover(day, hotelId),
                                } as ICell;
                            }),
                        },
                    ],
                })),
            },
        ];

        return data;
    }

    get tableConfig(): ITableConfig {
        return {
            height: '100%',
            width: '100%',
            cellSize: [
                {
                    width: ['90px', ['120px', '120px', '120px']],
                    height: ['50px'],
                },
                {
                    width: [['120px', '120px', '120px']],
                    height: ['50px'],
                },
            ],
        };
    }

    setTooltipData(day: Day, hotelId: number, focusElement: HTMLElement) {
        this.tooltipFocusElement = focusElement;
        this.tooltipDay = day;

        if (!this.disabledColumns.length) return;

        const { disabledColumns } = this;
        const preview = {} as { [k: string]: any };
        const mainKey = this.mainCompareTitle! || '';
        const compareKey = this.comparisonValues[0].name;
        let mainValue: number | string = '';
        let compareValue: number | string = '';

        if (disabledColumns.includes('Diff')) {
            const diff = this.formatDiff(day, hotelId);
            preview.Diff = { value: diff.value };
        }

        if (disabledColumns.includes(String(mainKey))) {
            ({ value: mainValue } = this.getRoomData(day, hotelId, false));
        }

        if (disabledColumns.includes(String(compareKey))) {
            ({ value: compareValue } = this.getRoomData(day, hotelId, true));
        }

        preview[mainKey] = { value: mainValue };
        preview[compareKey] = { value: compareValue };

        this.tooltipHotelName = false
            || this.ratesService.getHotelName(hotelId)
            || this.hotelsService.getHotelName(hotelId);

        this.analysisPreview = preview;
    }

    resetTooltipData() {
        this.tooltipFocusElement = null;
    }

    updateDisabledColumnsList(disabledColumns: string[]) {
        this.disabledColumns = disabledColumns;
    }

    isNoData(day: Day) {
        return !this.isPreparing
            && !this.ratesService.isNoData(day);
    }

    handleRowClick(day: Day) {
        const hotelId = this.$route.params.hotelId || this.userService.currentHotelId;

        if (!this.ratesService.isNoData(day) && hotelId) {
            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { hotelId: String(hotelId), day: String(day) },
            });
        }
    }

    handleChangeRow() {
        this.isOpened = false;
    }

    handleOpen() {
        this.isOpened = true;
    }

    dayToDate(day: Day): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date(year, month, day);
        const dayName = date
            .toLocaleDateString('en-US', { weekday: 'short' })
            .toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    mounted() {
        this.isPreparing = false;
    }

    formatPercent(value: number | string | null, maxFixed: number = 2, minFixed: number = 0) {
        if (value === null) return '0%';
        const formatConfig = {
            maximumFractionDigits: maxFixed,
            minimumFractionDigits: minFixed,
        };

        return `${(Number(value) * 100).toLocaleString('en-US', formatConfig)}%`;
    }

    formatPrice(value: number | string | null, maxFixed: number = 2, minFixed: number = 0) {
        if (value === null) return `${this.currency}0`;

        const formatConfig = {
            maximumFractionDigits: maxFixed,
            minimumFractionDigits: minFixed,
        };

        const formatedPrice = Number(value).toLocaleString('en-US', formatConfig);

        return `${this.currency} ${formatedPrice}`;
    }

    formatDiff(day: Day, hotelId: number) {
        const diff = this.ratesAnalysisService.getRoomDifference(day, hotelId, this.showPriceDiff);
        const formatMethod = this.showPriceDiff ? 'formatPrice' : 'formatPercent';

        return {
            value: this[formatMethod](diff),
            style: { color: diff < 0 ? '#E7472D' : 'grey' },
            onClick: () => this.handleRowClick(day),
        };
    }

    getRoomData(
        day: Day,
        hotelId: number,
        compare: boolean = false,
    ) {
        const data = {
            value: '',
            hasPrice: false,
            style: {},
            isBasic: false,
        };

        const service = compare
            ? this.ratesAnalysisService
            : this.ratesService;

        if (service.isOutOfRange()) {
            data.value = 'Out Of Range';
            data.style = { color: 'grey' };
            return data;
        }

        if (service.isNA(day, hotelId)) {
            data.value = 'N/A';
            data.style = { color: 'grey' };
            return data;
        }

        if (service.isNoData(day)) {
            data.value = 'No Data';
            data.style = { color: 'grey' };
            return data;
        }

        if (service.isSoldOut(day, hotelId)) {
            data.value = 'Sold Out';
            data.style = { color: 'grey' };
            return data;
        }

        const price = service.getPrice(day, hotelId)!;
        const room = service.getRoom(day, hotelId);

        data.hasPrice = true;
        data.value = this.formatPrice(price);
        data.style = { color: this.priceColor(day, price, compare) };
        data.isBasic = !!room && room.isBasic;

        return data;
    }

    priceColor(day: Day, price: number, compare = false) {
        const service = compare
            ? this.ratesAnalysisService
            : this.ratesService;

        const assessmentType = price
            ? service.getTableAssessment(price, day)
            : null;

        switch (assessmentType) {
            case ASSESSMENTS_TYPES.BAD:
                return '#E7472D';

            case ASSESSMENTS_TYPES.GOOD:
                return '#01B875';

            default: return null;
        }
    }
}
