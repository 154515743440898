

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';

@Component
export default class RatesAnalysisNoData extends Vue {
    @Inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @Inject(RatesAnalysisFiltersServiceS) ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    get filterCurrentName() {
        return this.ratesAnalysisFiltersService.filterComparisonName;
    }

    get hideForm() {
        return this.ratesAnalysisService.closeNoData;
    }

    set hideForm(value: boolean) {
        this.ratesAnalysisService.closeNoData = value;
    }

    handleClose() {
        this.hideForm = true;
    }

    handleRefresh() {
        this.hideForm = false;
        this.ratesAnalysisService.resetLoading();
    }
}
